<template>
  <div class="calendar-step step--3">
    <div class="calendar-step-left-wrapper">
      <ResumeCalendar
        :date="this.examinationStore.getFormattedDate"
        :time="this.examinationStore.time" />
    </div>
    <div class="calendar-step-right-wrapper">
      <div class="calendar-step-top-wrapper">
        <FormCalendar />
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

import ResumeCalendar from './ResumeCalendar.vue'
import FormCalendar from './FormCalendar.vue'

export default {
  name: 'Step3Calendar',
  components: {
    ResumeCalendar,
    FormCalendar,
  },
  computed: {
    ...mapStores(useExaminationStore),
  },
}
</script>
