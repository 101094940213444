<template>
  <div class="calendar-step step--1">
    <BigCard
      v-for="examination in $content.step1.examinations"
      :key="examination.id"
      :title="examination.title"
      :description="examination.description"
      @click="
        chooseExamination(
          examination.id,
          examination.title,
          examination.calendarId
        )
      " />
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

import BigCard from '../ui/BigCard.vue'

export default {
  name: 'Step1Calendar',
  components: {
    BigCard,
  },
  computed: {
    ...mapStores(useExaminationStore),
  },
  methods: {
    chooseExamination(examinationId, examinationTitle, calendarId) {
      this.examinationStore.setExaminationId(examinationId)
      this.examinationStore.setExaminationTitle(examinationTitle)
      this.examinationStore.setCalendarId(calendarId)
      this.examinationStore.incrementStep()
    },
  },
}
</script>
