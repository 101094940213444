<template>
  <div class="header-calendar">
    <div class="header-calendar-left">
      <h1 class="header-calendar-title">{{ title }}</h1>
    </div>
    <div class="header-calendar-right">
      <div class="progress-indicator">
        <div class="step step--current">0{{ currentStep }}</div>
        <div class="step-separator">·</div>
        <div class="step step--final">0{{ totalSteps - 1 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderCalendar',
  props: {
    title: String,
    currentStep: Number,
    totalSteps: Number,
  },
}
</script>
