import {createRouter, createWebHistory} from 'vue-router'
import CalendarPage from '../pages/CalendarPage.vue'

const routes = [
  {
    path: '/',
    name: 'calendar-page',
    component: CalendarPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
