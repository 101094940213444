import {createApp} from 'vue'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import {SetupCalendar} from 'v-calendar'
import vSelect from 'vue-select'

import App from './App.vue'
import Router from './router'

import * as texts from './assets/content/texts.js'
import * as slotsConfig from './assets/content/slotsConfig.js'

import 'vue-select/dist/vue-select.css'

const app = createApp(App)

app.config.globalProperties.$content = texts
app.config.globalProperties.$slotsConfig = slotsConfig
app.use(Router)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(SetupCalendar, {})

app.component('v-select', vSelect)

app.mount('#app')
