<template>
  <div class="card card--big">
    <div class="card-top">
      <svg class="icon icon--calendar">
        <use xlink:href="#icon-calendar"></use>
      </svg>
    </div>
    <div class="card-bottom">
      <div class="card-title">{{ title }}</div>
      <div class="card-description">{{ description }}</div>
      <div class="card-cta">
        <svg class="icon icon--arrow-circle">
          <use xlink:href="#icon-arrow-circle"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BigCard',
  props: {
    id: String,
    title: String,
    description: String,
  },
}
</script>
