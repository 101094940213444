<template>
  <div class="resume">
    <div class="resume-title">Visita generica</div>
    <div class="resume-info">
      <svg class="icon icon--clock-resume icon--stroke-green-gray">
        <use xlink:href="#icon-clock"></use>
      </svg>
      <span>{{ $content.duration }}</span>
    </div>
    <div class="resume-info">
      <svg class="icon icon--pin-resume icon--stroke-green-gray">
        <use xlink:href="#icon-pin"></use>
      </svg>
      <span>{{ $content.place }}</span>
    </div>
    <div class="resume-reservation" v-show="date != null && time != null">
      <svg class="icon icon--calendar-resume icon--fill-green-gray">
        <use xlink:href="#icon-calendar"></use>
      </svg>
      <div class="resume-reservation-text">
        <b>{{ date }}</b
        ><br />
        Ore {{ time }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResumeCalendar',
  props: {
    date: String,
    time: String,
  },
}
</script>
