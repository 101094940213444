import {defineStore} from 'pinia'
const dayjs = require('dayjs')
require('dayjs/locale/it')
dayjs.locale('it')

export const useExaminationStore = defineStore('examination', {
  state: () => {
    return {
      currentStep: 1,
      totalSteps: 4,
      examinationId: null,
      examinationTitle: null,
      calendarId: null,
      day: null,
      time: null,
      reservationData: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        dogBreed: '',
        notes: '',
      },
      step2DataFilled: false,
      step3DataFilled: false,
      reservationSent: false,
      storeInit: dayjs(),
    }
  },

  getters: {
    getFormattedDate: (state) => {
      return state.day != null ? dayjs(state.day).format('DD MMMM YYYY') : null
    },
    getReservationData: (state) => {
      return state.reservationData
    },
    getReservationDataFormatted: (state) => {
      return (
        state.reservationData.surname +
        ' ' +
        state.reservationData.name +
        '<br>' +
        'Email: ' +
        state.reservationData.email +
        '<br>' +
        'Tel: ' +
        state.reservationData.phone +
        '<br>' +
        'Razza: ' +
        state.reservationData.dogBreed +
        '<br><br>' +
        'Note: <br>' +
        state.reservationData.notes
      )
    },
  },

  actions: {
    incrementStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++
      }
    },
    reduceStep() {
      if (this.currentStep > 1) {
        this.currentStep--
      }
    },
    setExaminationId(id) {
      this.examinationId = id
    },
    setExaminationTitle(title) {
      this.examinationTitle = title
    },
    setCalendarId(id) {
      this.calendarId = id
    },
    setDay(day) {
      this.day = day
    },
    setTime(time) {
      this.time = time
    },
    setStep2DataFilled(value) {
      this.step2DataFilled = value
    },
    setReservationData(name, surname, email, phone, dogBreed, notes) {
      this.reservationData = {
        name,
        surname,
        email,
        phone,
        dogBreed,
        notes,
      }
    },
  },

  persist: true,
})
