<template>
  <div class="navigation-bar">
    <div class="left-wrapper">
      <button class="btn btn--full-width" @click="previousStep()">
        {{ $content.back }}
      </button>
    </div>
    <div class="right-wrapper">
      <button
        class="btn btn--primary btn--full-width"
        :disabled="!nextStepEnabled"
        @click="nextStep()">
        {{ $content.proceed }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

export default {
  name: 'NavigationBarCalendar',
  props: {
    nextStepEnabled: Boolean,
  },
  computed: {
    ...mapStores(useExaminationStore),
  },
  methods: {
    previousStep() {
      this.examinationStore.reduceStep()
    },
    nextStep() {
      this.examinationStore.incrementStep()
    },
  },
}
</script>
