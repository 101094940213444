<template>
  <form
    id="calendar-form"
    class="form"
    @submit.prevent="submitForm"
    autocomplete="off">
    <div class="form-main-wrapper">
      <div class="form-item form-item--name">
        <label class="form-label" for="user-name">Nome*</label>
        <input
          class="form-field"
          type="text"
          id="user-name"
          name="user-name"
          v-model.trim="userName"
          autocomplete="do-not-autofill"
          :class="{error: v$.userName.$error}"
          @keyup="fieldHasChanged"
          @blur="v$.userName.$touch" />
        <div class="form-input-errors">
          <div
            v-for="error of v$.userName.$errors"
            :key="error.$uid"
            class="error-msg">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="form-item form-item--surname">
        <label class="form-label" for="user-surname">Cognome*</label>
        <input
          class="form-field"
          type="text"
          id="user-surname"
          name="user-surname"
          v-model.trim="userSurname"
          autocomplete="do-not-autofill"
          :class="{error: v$.userSurname.$error}"
          @keyup="fieldHasChanged"
          @blur="v$.userSurname.$touch" />
        <div class="form-input-errors">
          <div
            v-for="error of v$.userSurname.$errors"
            :key="error.$uid"
            class="error-msg">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="form-item form-item--email">
        <label class="form-label" for="user-email">E-mail*</label>
        <input
          class="form-field"
          type="email"
          id="user-email"
          name="user-email"
          v-model.trim="userEmail"
          autocomplete="do-not-autofill"
          :class="{error: v$.userEmail.$error}"
          @keyup="fieldHasChanged"
          @blur="v$.userEmail.$touch" />
        <div class="form-input-errors">
          <div
            v-for="error of v$.userEmail.$errors"
            :key="error.$uid"
            class="error-msg">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="form-item form-item--phone">
        <label class="form-label" for="user-phone">Recapito telefonico*</label>
        <div class="form-field-icon-wrapper">
          <svg class="icon icon--flag">
            <use xlink:href="#icon-italy-flag"></use>
          </svg>
          <input
            class="form-field"
            type="tel"
            id="user-phone"
            name="user-phone"
            v-model.trim="userPhone"
            autocomplete="do-not-autofill"
            :class="{error: v$.userPhone.$error}"
            @keyup="fieldHasChanged"
            @blur="v$.userPhone.$touch" />
        </div>
        <div class="form-input-errors">
          <div
            v-for="error of v$.userPhone.$errors"
            :key="error.$uid"
            class="error-msg">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="form-item form-item--dog-breed">
        <label class="form-label" for="user-dog-breed">Razza*</label>
        <v-select
          id="user-dog-breed"
          name="user-dog-breed"
          v-model="userDogBreed"
          :class="{error: v$.userDogBreed.$error}"
          @change="fieldHasChanged"
          @click="v$.userDogBreed.$touch"
          :options="$content.dogBreeds">
          <!-- eslint-disable-next-line no-deprecated-slot-attribute -->
          <!-- <div slot="no-options">Nessun risultato trovato</div> -->
        </v-select>
        <div class="form-input-errors">
          <div
            v-for="error of v$.userDogBreed.$errors"
            :key="error.$uid"
            class="error-msg">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="form-item form-item--notes">
        <label class="form-label" for="user-notes">Campo note</label>
        <textarea
          class="form-field"
          name="user-notes"
          id="user-notes"
          v-model="userNotes"
          @keyup="fieldHasChanged"></textarea>
      </div>
    </div>
    <div class="form-item form-item--privacy-policy">
      <input
        type="checkbox"
        id="user-privacy-policy"
        name="user-privacy-policy"
        v-model="userPrivacyPolicy"
        :class="{error: v$.userPrivacyPolicy.$error}"
        @click="fieldHasChanged"
        @blur="v$.userPrivacyPolicy.$touch" />
      <label for="user-privacy-policy" class="label-checkbox"
        >* Accettazione privacy policy</label
      >
      <div class="form-input-errors">
        <div
          v-for="error of v$.userPrivacyPolicy.$errors"
          :key="error.$uid"
          class="error-msg">
          {{ error.$message }}
        </div>
      </div>
    </div>
    <div class="form-item form-item--required-fields">
      * I campi contrassegnati con l'asterisco sono obbligatori
    </div>
  </form>
  <NavigationBarCalendar :next-step-enabled="!this.v$.$invalid" />
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

import {useVuelidate} from '@vuelidate/core'
import {required, email, numeric, sameAs, helpers} from '@vuelidate/validators'

import NavigationBarCalendar from './NavigationBarCalendar.vue'

export default {
  name: 'FormCalendar',
  components: {
    NavigationBarCalendar,
  },
  setup: () => ({v$: useVuelidate({$autoDirty: true})}),
  data() {
    return {
      userName: '',
      userSurname: '',
      userEmail: '',
      userPhone: '',
      userDogBreed: '',
      userNotes: '',
      userPrivacyPolicy: false,
    }
  },
  computed: {
    ...mapStores(useExaminationStore),
  },
  validations() {
    return {
      userName: {
        required: helpers.withMessage(
          this.$content.formValidation.required,
          required
        ),
      },
      userSurname: {
        required: helpers.withMessage(
          this.$content.formValidation.required,
          required
        ),
      },
      userEmail: {
        required: helpers.withMessage(
          this.$content.formValidation.required,
          required
        ),
        email: helpers.withMessage(this.$content.formValidation.email, email),
      },
      userPhone: {
        required: helpers.withMessage(
          this.$content.formValidation.required,
          required
        ),
        numeric: helpers.withMessage(
          this.$content.formValidation.numeric,
          numeric
        ),
      },
      userDogBreed: {
        required: helpers.withMessage(
          this.$content.formValidation.required,
          required
        ),
      },
      userPrivacyPolicy: {
        sameAs: helpers.withMessage(
          this.$content.formValidation.privacy,
          sameAs(true)
        ),
      },
    }
  },
  methods: {
    click() {
      this.$v.$touch()
    },
    fieldHasChanged() {
      this.examinationStore.setReservationData(
        this.userName,
        this.userSurname,
        this.userEmail,
        this.userPhone,
        this.userDogBreed,
        this.userNotes
      )
    },
    async submitForm() {
      const validationResult = await this.v$.$validate()
      if (validationResult) {
        console.log('Submit')
      }
    },
  },
  mounted() {
    // retrieve form data from store
    let reservationData = this.examinationStore.getReservationData
    this.userName = reservationData.name
    this.userSurname = reservationData.surname
    this.userEmail = reservationData.email
    this.userPhone = reservationData.phone
    this.userDogBreed = reservationData.dogBreed
    this.userNotes = reservationData.notes
  },
}
</script>
