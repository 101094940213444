<template>
  <div class="calendar-step step--2">
    <div class="calendar-step-left-wrapper">
      <ResumeCalendar />
    </div>
    <div class="calendar-step-right-wrapper">
      <div class="calendar-step-top-wrapper">
        <SelectorCalendar />
      </div>
    </div>
  </div>
</template>

<script>
import ResumeCalendar from './ResumeCalendar.vue'
import SelectorCalendar from './SelectorCalendar.vue'

export default {
  name: 'Step2Calendar',
  components: {
    ResumeCalendar,
    SelectorCalendar,
  },
}
</script>
