<template>
  <header>
    <div class="left-wrapper">
      <svg class="icon icon--logo">
        <use xlink:href="#logo"></use>
      </svg>
    </div>
    <div class="right-wrapper" v-if="this.examinationStore.step < 4">
      <div class="goto-mainsite">
        Torna su <a href="https://sportydog.vet/">sportydog.vet</a>
      </div>
    </div>
  </header>
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

export default {
  name: 'HeaderLayout',
  computed: {
    ...mapStores(useExaminationStore),
  },
}
</script>
