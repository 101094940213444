<template>
  <div class="calendar-step step--4">
    <div class="calendar-step-left-wrapper">
      <div class="calendar-step-left-top-wrapper" v-if="restCallValid">
        <div class="step4-title">{{ $content.step4.title }}</div>
        <div class="step4-description">
          {{ $content.step4.description }}
        </div>
        <a
          rel="noopener"
          :href="gCalendarLink"
          target="_blank"
          class="step4-cta-gcalendar cta"
          >{{ $content.step4.addGoogleCalendar }}</a
        >
      </div>
      <div class="calendar-step-left-top-wrapper" v-if="!restCallValid">
        <div class="step4-description">
          {{ $content.restError.msg1 }}<br />{{ $content.restError.msg2 }}
        </div>
      </div>
      <div class="calendar-step-left-bottom-wrapper">
        <div class="step4-actions">
          <a
            href="#"
            class="step4-newreservation btn"
            @click.prevent="this.examinationStore.$reset">
            {{ $content.step4.newReservation }}
          </a>
          <a
            href="https://sportydog.vet/"
            class="step4-newreservation btn"
            target="_blank">
            {{ $content.step4.backToMainSite }}
          </a>
        </div>
        <div class="step4-disclaimer">
          {{ $content.step4.disclaimer }}<br />
          <a href="mailto:">{{ $content.step4.disclaimerCta }}</a>
        </div>
      </div>
    </div>
    <div class="calendar-step-right-wrapper"></div>
    <img
      class="image image--fixed-full image--line"
      src="../../assets/images/linea-gialla.png" />
    <img
      class="image image--fixed-full image--dog"
      src="../../assets/images/dalmata-min.png" />
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

const dayjs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')
var dayOfYear = require('dayjs/plugin/dayOfYear')

require('dayjs/locale/it')
dayjs.extend(customParseFormat)
dayjs.extend(dayOfYear)

dayjs.locale('it')

export default {
  name: 'StepFinal',
  data() {
    return {
      gCalendarLink:
        'https://calendar.google.com/calendar/render?action=TEMPLATE',
      restCallValid: true,
    }
  },
  computed: {
    ...mapStores(useExaminationStore),
  },
  methods: {
    parseDateForGCalendar(dayjsDate) {
      return (
        dayjsDate.format('YYYYMMDD') +
        'T' +
        dayjsDate.format('HHmm') +
        'Z' +
        dayjsDate.format('Z')
      )
    },
    createPostObject(dateStart, dateEnd, gCalendarLink) {
      return {
        info: {
          examinationId: this.examinationStore.examinationId,
          examinationTitle: this.examinationStore.examinationTitle,
          gCalendarLink: encodeURI(gCalendarLink),
        },
        gCalendarEvent: {
          summary:
            this.examinationStore.reservationData.surname +
            ' ' +
            this.examinationStore.reservationData.name,
          description: this.examinationStore.getReservationDataFormatted,
          creator: {
            email: this.$slotsConfig.creatorEmail,
          },
          organizer: {
            email: this.$slotsConfig.creatorEmail,
          },
          start: {
            dateTime: dateStart.format(),
            timeZone: 'Europe/Rome',
          },
          end: {
            dateTime: dateEnd.format(),
            timeZone: 'Europe/Rome',
          },
          attendees: [],
          reminders: {
            useDefault: true,
          },
        },
        reservationData: JSON.parse(
          JSON.stringify(this.examinationStore.getReservationData)
        ),
      }
    },
    updateGCalendarLink(dateStart, dateEnd) {
      // generate gCalendarLink and parameters
      const eventTitle =
        'text=' + encodeURI(this.examinationStore.examinationTitle)
      const eventDates = encodeURI(
        `dates=${this.parseDateForGCalendar(
          dateStart
        )}/${this.parseDateForGCalendar(dateEnd)}`
      )

      const eventLocation = `location=${this.$content.eventLocation}`
      const eventDetails =
        `details=${this.$content.confirmation} ${this.examinationStore.examinationTitle}<br>` +
        `${this.$content.title}<br>${this.$content.place}<br>${this.$content.duration}<br><br>` +
        this.examinationStore.reservationData.notes

      this.gCalendarLink =
        this.gCalendarLink +
        '&' +
        eventTitle +
        '&' +
        eventDates +
        '&' +
        eventLocation +
        '&' +
        eventDetails
    },
  },
  mounted() {
    const timeParsing = this.examinationStore.time.split(':')
    let dayTimeStart = dayjs(this.examinationStore.day)

    dayTimeStart = dayTimeStart
      .hour(timeParsing[0])
      .minute(timeParsing[1])
      .second(0)

    let dayTimeEnd = dayTimeStart.add(59, 'minute')

    this.updateGCalendarLink(dayTimeStart, dayTimeEnd)

    const urlRESTcall = this.$slotsConfig.restServerUrl + 'create'

    const postObject = this.createPostObject(
      dayTimeStart,
      dayTimeEnd,
      this.gCalendarLink
    )

    console.log('Url REST call: ', urlRESTcall)
    console.log('Post object: ', postObject)

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postObject),
    }

    if (!this.examinationStore.reservationSent) {
      fetch(urlRESTcall, requestOptions)
        .then((response) => response.json())
        .then(async () => {
          this.examinationStore.reservationSent = true
        })
        .catch((error) => {
          console.log('Error: \n', error)
          this.restCallValid = false
        })
    }
  },
}
</script>
