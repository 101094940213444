<template>
  <div class="selector-calendar" v-if="restCallValid">
    <div class="selector-days-wrapper">
      <DatePicker
        v-model="selectedDate"
        is-expanded
        title-position="left"
        :locale="{id: 'it', firstDayOfWeek: 2, masks: {weekdays: 'WWW'}}"
        :min-date="minDate"
        :max-date="maxDate"
        :available-dates="availableDates"
        :disabled-dates="disabledDates"
        :attributes="calendarAttributes"
        @dayclick="updateSlotList" />
    </div>
    <div class="selector-times-wrapper">
      <div class="selector-selected-date">
        {{ selectedDateFormatted }}
      </div>
      <div class="time-slots-wrapper">
        <div
          v-for="slot in slotList"
          :key="slot"
          class="time-slot"
          :class="{selected: selectedTimeSlot === slot}"
          @click="selectTimeSlot(slot)">
          {{ slot }}
        </div>
      </div>
    </div>
  </div>
  <div class="selector-calendar" v-if="!restCallValid">
    <div class="rest-error-message">
      {{ $content.restError.msg1 }}<br />{{ $content.restError.msg2 }}
    </div>
  </div>
  <NavigationBarCalendar
    :next-step-enabled="this.examinationStore.step2DataFilled" />
</template>

<script>
import 'v-calendar/dist/style.css'
import {DatePicker} from 'v-calendar'

import {mapStores} from 'pinia'
import {useExaminationStore} from '../../store/store.js'

import NavigationBarCalendar from './NavigationBarCalendar.vue'

const dayjs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const dayOfYear = require('dayjs/plugin/dayOfYear')
const utc = require('dayjs/plugin/utc')

require('dayjs/locale/it')
dayjs.extend(customParseFormat)
dayjs.extend(dayOfYear)
dayjs.extend(utc)

dayjs.locale('it')

export default {
  name: 'SelectorCalendar',
  components: {
    DatePicker,
    NavigationBarCalendar,
  },
  data() {
    return {
      // time slots defined from external configuration
      timeSlots: this.$slotsConfig.timeSlots,
      // already booked reservations from gCalendar - REST API
      gCalendarResults: null,
      // map including available dates and time slots
      availableTimeSlotsMap: null,
      // v-calendar variables and params
      selectedDate: null, // date selected by date picker
      selectedDateFormatted: null,
      minDate: dayjs().add(1, 'day').hour(0).minute(0).toDate(), // min date starting from tomorrow
      maxDate: dayjs().add(3, 'month').hour(23).minute(59).toDate(), // max date in 3 months range
      availableDates: null, // already filtered available dates
      disabledDates: {}, // empty for rendering reasons (bugfix)
      calendarAttributes: [], // calendar attributes
      // slots options
      selectedTimeSlot: null,
      slotList: [],
      restCallValid: true,
    }
  },
  computed: {
    ...mapStores(useExaminationStore),
    // from defined time slots it computes the available days
    // of the week for making a reservation (sunday = 1)
    reservationWeekDays() {
      const days = []

      for (let slot of this.timeSlots) {
        days.push(slot.weekDay)
      }

      return days
    },
  },
  watch: {
    selectedDay() {
      this.updateSelectedDayTimeStore()
    },
    selectedTimeSlot() {
      this.updateSelectedDayTimeStore()
    },
  },
  methods: {
    // generate map key starting from a date
    mapKeyFromDate(date) {
      return dayjs(date).format('YYYYMMDD').toString()
    },
    // purge available time slots removing all the reservations already
    // stored in the gCalendar
    parseGCalendarResults() {
      // console.log('\n-------------------------')
      // console.log('PARSING GCALENDAR RESULTS')
      // console.log('-------------------------\n\n')

      // for every gCalendar event
      for (let event of this.gCalendarResults) {
        const dateStart = new Date(event.start.dateTime)
        const dateEnd = new Date(event.end.dateTime)
        const hashKey = this.mapKeyFromDate(dateStart)
        const hourDayStart = dayjs(dateStart).hour()
        const minuteDayStart = dayjs(dateStart).minute()
        const hourDayEnd = dayjs(dateEnd).hour()
        const minuteDayEnd = dayjs(dateEnd).minute()
        const timeStart = hourDayStart * 60 + minuteDayStart
        const timeEnd = hourDayEnd * 60 + minuteDayEnd

        // console.log(`Event`)
        // console.log(`- dateStart ${dateStart}`)
        // console.log(`- dateEnd ${dateEnd}`)
        // console.log(`- hashKey ${hashKey}`)
        // console.log(`- timeStart ${timeStart}`)
        // console.log(`- timeEnd ${timeEnd}`)

        const tmpSlots = this.availableTimeSlotsMap.get(hashKey)
        let found = false

        console.log(`- tmpSlots ${tmpSlots}`)

        // if the event is inside the available dates range
        if (tmpSlots) {
          let index, slot
          let indexesToPurge = []

          for ([index, slot] of tmpSlots.entries()) {
            const tmpHour = parseInt(slot.slice(0, 2))
            const tmpMinute = parseInt(slot.slice(3, 6))
            const tmpTimeStart = tmpHour * 60 + tmpMinute
            const tmpTimeEnd = tmpTimeStart + 60

            // console.log(` + Slot ${slot}`)
            // console.log(`   - tmpTimeStart ${tmpTimeStart}`)
            // console.log(`   - tmpTimeEnd ${tmpTimeEnd}`)

            if (
              (timeStart >= tmpTimeStart && timeStart < tmpTimeEnd) ||
              (timeEnd > tmpTimeStart && timeEnd <= tmpTimeEnd)
            ) {
              // console.log('   !!! FOUND !!!')
              found = true
              indexesToPurge.push(index)
            }
          }

          if (found) {
            for (let i = indexesToPurge.length - 1; i >= 0; i--) {
              tmpSlots.splice(indexesToPurge[i], 1)
            }

            if (tmpSlots.length > 0) {
              this.availableTimeSlotsMap.set(hashKey, tmpSlots)
            } else {
              this.availableTimeSlotsMap.delete(hashKey)
            }
          }
        }
      }
    },
    // generate all possible reservation slots
    // in a specific range of dates
    initAvailableTimeSlots(startDate, endDate) {
      const map = new Map()
      const nationalHolidays = []
      const extraClosings = []
      let tmpDate = startDate

      // create national holidays array from external configuration
      // converting to absolute number (day of the year)
      for (const day of this.$slotsConfig.excludedDates.nationalHolidays) {
        nationalHolidays.push(dayjs(day, 'DD/MM', true).dayOfYear())
      }

      // create extra closings array from external configuration
      // converting to absolute number (day of the year)
      for (const day of this.$slotsConfig.excludedDates.extraClosings) {
        extraClosings.push(dayjs(day, 'DD/MM', true).dayOfYear())
      }

      // generate all possible slots between range dates
      while (tmpDate <= endDate) {
        const tmpDateJs = dayjs(tmpDate)
        const currentWeekDay = dayjs(tmpDate).day() + 1
        const dayIndex = this.reservationWeekDays.indexOf(currentWeekDay)

        // check if it is an available week day
        if (dayIndex >= 0) {
          // check if it is a national holiday day
          // or extra closure
          if (
            !nationalHolidays.includes(tmpDateJs.dayOfYear()) &&
            !extraClosings.includes(tmpDateJs.dayOfYear())
          ) {
            const hashKey = this.mapKeyFromDate(tmpDate)
            const slots = [...this.timeSlots[dayIndex].slots]
            map.set(hashKey, slots)
          }
        }

        tmpDate = tmpDateJs.add(1, 'day').toDate()
      }

      return map
    },
    // starting from available time slots map
    // it generates an array for available dates
    getCompatibleDates() {
      let dates = []

      const keys = [...this.availableTimeSlotsMap.keys()]

      for (const key of keys) {
        dates.push(dayjs(key, 'YYYYMMDD').toDate())
      }

      return dates
    },
    updateSlotList() {
      this.selectedTimeSlot = null
      this.slotList = this.availableTimeSlotsMap.get(
        this.mapKeyFromDate(this.selectedDate)
      )

      if (dayjs(this.selectedDate).isValid()) {
        this.selectedDateFormatted = dayjs(this.selectedDate).format(
          'DD MMMM YYYY'
        )
      } else {
        this.selectedDateFormatted = null
      }
    },
    selectTimeSlot(id) {
      this.selectedTimeSlot = id
    },
    updateSelectedDayTimeStore() {
      // update day and time info into permanent store

      let completeDayTime = dayjs(this.selectedDate)

      if (this.selectedTimeSlot != null) {
        const timeParsing = this.selectedTimeSlot.split(':')
        completeDayTime = completeDayTime.hour(timeParsing[0])
        completeDayTime = completeDayTime.minute(timeParsing[1])
        completeDayTime = completeDayTime.second(0)
      }

      this.examinationStore.setDay(completeDayTime.local())
      this.examinationStore.setTime(this.selectedTimeSlot)

      if (this.selectedDate != null && this.selectedTimeSlot != null) {
        this.examinationStore.setStep2DataFilled(true)
      } else {
        this.examinationStore.setStep2DataFilled(false)
      }
    },
  },
  mounted() {
    // initialize variable for step 3
    this.examinationStore.setStep2DataFilled(false)

    // generate all possible available time slots
    // according to configuration file slotsConfig.json
    this.availableTimeSlotsMap = this.initAvailableTimeSlots(
      this.minDate,
      this.maxDate
    )

    // REST call to server
    // console.log('Min date: ', dayjs(this.minDate).format())
    // console.log('Max date: ', dayjs(this.maxDate).format())

    const urlRESTcall = this.$slotsConfig.restServerUrl + 'lists/'

    // console.log('Url REST call: ', urlRESTcall)

    const postObject = {
      calendar_id:
        'ef22c12fa644b4f318cabd24b5f78e2e8c5df2a64d69231bb2558ace66c62d2f@group.calendar.google.com',
      date_start: dayjs(this.minDate).format(),
      date_end: dayjs(this.maxDate).format(),
    }

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postObject),
    }

    fetch(
      urlRESTcall,
      // 'fakeGCalendar.json',
      requestOptions
    )
      .then((res) => res.json())
      .then((response) => {
        // console.log('Response REST: ', {response})

        this.gCalendarResults = response

        // purge available slots accoring to GCalendar results
        this.parseGCalendarResults()

        // init available dates
        this.availableDates = this.getCompatibleDates()

        // set highlighted dates to the calendar
        this.calendarAttributes.push({
          key: 'availableDatesHighlighted',
          highlight: {
            color: 'gray',
            fillMode: 'light',
            contentClass: 'regular-highlighted',
          },
          dates: this.availableDates,
        })
      })
      .catch((error) => {
        console.log('Error: \n', error)
        this.restCallValid = false
      })
  },
}
</script>
