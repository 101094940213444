<template>
  <main class="page page--calendar">
    <HeaderLayout />
    <section class="main-content">
      <div class="main-content-wrapper">
        <HeaderCalendar
          :title="calendarTitle"
          :current-step="this.examinationStore.currentStep"
          :total-steps="this.examinationStore.totalSteps"
          v-if="this.examinationStore.currentStep < 4" />
        <Transition mode="out-in">
          <Step1Calendar v-if="this.examinationStore.currentStep == 1" />
          <Step2Calendar v-else-if="this.examinationStore.currentStep == 2" />
          <Step3Calendar v-else-if="this.examinationStore.currentStep == 3" />
          <StepFinal v-else-if="this.examinationStore.currentStep == 4" />
        </Transition>
      </div>
    </section>
  </main>
</template>

<script>
import {mapStores} from 'pinia'

import {useExaminationStore} from '../store/store.js'

const dayjs = require('dayjs')

require('dayjs/locale/it')
dayjs.locale('it')

import HeaderLayout from '../components/layout/HeaderLayout.vue'
import HeaderCalendar from '../components/calendar/HeaderCalendar.vue'
import Step1Calendar from '../components/calendar/Step1Calendar.vue'
import Step2Calendar from '../components/calendar/Step2Calendar.vue'
import Step3Calendar from '../components/calendar/Step3Calendar.vue'
import StepFinal from '../components/calendar/StepFinal.vue'

export default {
  name: 'CalendarPage',
  components: {
    HeaderLayout,
    HeaderCalendar,
    Step1Calendar,
    Step2Calendar,
    Step3Calendar,
    StepFinal,
  },
  computed: {
    ...mapStores(useExaminationStore),
    calendarTitle() {
      return this.$content.steps[this.examinationStore.currentStep - 1]
    },
  },
  data() {
    return {}
  },
  mounted() {
    // check whether to reset the store - 10 minutes
    if (dayjs().diff(this.examinationStore.storeInit, 'minute', true) > 10) {
      this.examinationStore.$reset()
    }
  },
}
</script>
