<template>
  <router-view></router-view>
  <SvgSymbols />
</template>

<script>
import SvgSymbols from './components/SvgSymbols.vue'

export default {
  name: 'App',
  components: {
    SvgSymbols,
  },
}
</script>

<style lang="scss">
* {
}
</style>
